:root {
  --primary-color: #1E1E29;
  --secondary-color: #DED2C7;
  --accent-color: #B87E9D; 
  --border-color: #C7922F;
  --success-color: #4CAF50;
}


.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.logo-container {
  display: flex;
  align-items: center;
}
.logo {
  height: 100px;
  margin-right: 20px;
}
.App-header {
  background-color: var(--primary-color);
  min-height: 10vh; /* Set the minimum height for the header */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--secondary-color);
}


/* Style the h1 element inside the header */
.App-header h1 {
  font-size: 4.5vh; 
  margin: 0;
  font-weight: bold;
  letter-spacing: 2px;
}


.App-link {
  color: var(--accent-color);
}


.download-button {
  background-color: var(--success-color);
  color: var(--secondary-color);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}


.file-container {
  display: flex;
  align-items: center;
  width: 500px;
}


.file-name {
  flex: 1;
  margin-right: 10px;
  color: var(--primary-color);
}


.file-timestamp {
  color: var(--border-color);
}


.file-size {
  color: var(--primary-color);
}


table {
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
}


th, td {
  text-align: left;
  padding: 8px;
}


tr:nth-child(even){
  background-color: #f2f2f2
}


th {
  background-color: var(--accent-color);
  color: var(--secondary-color);
}

table {
  margin: 0 auto; /* center align the table */
}


.code-snippet {
  width: 300px;
  background: #afb3b8;
  padding: 0.5rem;
}

.code-section {
  position: relative;
  width: 100%;
}


.code-section pre {
  max-height: 300px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-y: hidden;
}
.code-section span {
  position: absolute;
  right: 0;
  top: 5%;
  bottom: 0;
  font-size: 120%;
  
}