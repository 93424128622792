.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  color: white;
  padding: 2rem;
  font-size: 1.5rem;
}

.release-list {
  padding: 1rem;
}

.release-list h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.release-list ul {
  list-style-type: none;
  padding: 0;
}

.release-list li {
  background-color: #f5f5f5;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
}

.release-list h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}