.box-component {
    width: 300px;
    padding: 20px;
    border: 1px solid #333;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    margin: 0 auto;
  }
  
  .box-title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;
    padding: 10px 0;
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .title-icon {
    width: 50;
    height: 50px;
  }
  
  .box-description {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .download-button {
    padding: 10px 20px;
    font-size: 18px;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .download-button:hover {
    background-color: #0056b3;
  }
  